import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from '@env/environment';
import { Helpers } from './helpers';


@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  initAmplitude(userId: string) {
    amplitude.init(environment.amplitudeApiKey, userId, {
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: false,
        fileDownloads: false,
        formInteractions: false,
      },
    });
    this.setUserIdentifier();
  }

  trackEvent(eventName: string, eventData?: any) {
    if (environment.production) {
      amplitude.track(eventName, eventData);
    } else {
      console.log('trackEvent', eventName, eventData);
    }
  }

  setUserIdentifier(identifierKey?: string, identifierValue?: string) {
    const identifyEvent = new amplitude.Identify();
    if (identifierKey && identifierValue) {
      identifyEvent.set(identifierKey, identifierValue);
    } else {
      identifyEvent.set('role', 'customer');
    }
  }
}
