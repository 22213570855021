import { Component, OnDestroy, OnInit } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Router } from '@angular/router';
import { ToggleButtonShape } from '../../entities/constant';
import { Service } from '../../interfaces/service.interface'
import { AppointmentsService } from '../../api-service/appointments/appointments.service'
import { Path } from '../../app-routing.config'
import { NavigationService } from '../../entities/navigation.service'
import { AppointmentFormModel } from '../../interfaces/appointment.interface'
import { DatePipe } from '@angular/common'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AppActions } from '../../state/app-state.action'
import { ListItemsService } from '../../entities/list-items.service'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Branch } from '../../interfaces/branch.interface'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  ToggleButtonShape = ToggleButtonShape;
  selectedServices: Service[] = [];
  isSelected: boolean = false;
  formValue: AppointmentFormModel;
  filters = null;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private appointmentsService: AppointmentsService,
    private navigationService: NavigationService,
    private datePipe: DatePipe,
    private listItemsService: ListItemsService,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);
      this.filters = this.getActualFilters();
      this.selectedServices = [...this.formValue?.services] || [];
      this.listItemsService.connectedItems.next(this.selectedServices);

      this.onOpenServicesSelection();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenServicesSelection(): void {
    this.amplitudeService.trackEvent(
      'widget_service_opened',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
      }
    )
  }

  goToHomePage() {
    this.router.navigate([this.branchSlug], {
      queryParamsHandling: 'merge',
    });
  }

  addService(service: Service) {
    this.selectedServices.push(service);
  }

  removeService(service: Service) {
    const index = this.selectedServices.findIndex(el => el.id === service.id);
    this.selectedServices.splice(index, 1);
  }

  selectService() {
    if (this.selectedServices.length > 0) {
      const totalPrice = this.getSelectedServicesPrice();
      const totalDuration = this.getSelectedServicesDuration();

      this.store.dispatch(new AppActions.UpdateAppointmentForm({
        services: [...this.selectedServices],
        totalPrice: totalPrice,
        totalDuration: totalDuration,
      }));

      this.amplitudeService.trackEvent(
      'widget_services_selected',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        service_ids: this.selectedServices.map(service => service.id),
        services_sum: this.selectedServices.length,
        appointment_price: totalPrice,
        appointment_duration: totalDuration / 60, // convert to minutes
      });
    }
  }

  getSelectedServicesDuration() {
    return this.appointmentsService.getTotalDuration([...this.selectedServices]);
  }

  getSelectedServicesPrice() {
    return String(this.appointmentsService.getTotalPrice([...this.selectedServices]));
  }

  getActualFilters() {
    if (!this.formValue) {
      return {}
    }

    const filters = {};

    const selectedEmployeeId = this.formValue?.employee?.id || null
    if (selectedEmployeeId) {
      filters['employee_ids'] = selectedEmployeeId;
    }

    const selectedDate = this.formValue?.date || null;
    if (selectedDate) {
      const shortenedTime = selectedDate.timeSlot.split(":").slice(0, 2).join(":");
      const shortenedDate = this.datePipe.transform(selectedDate.date, 'yyyy-MM-dd');
      filters['date'] = new Date(`${shortenedDate} ${shortenedTime} UTC`).toISOString();
    }

    filters['active'] = true;

    return filters;
  }

  goToEmployeeSetUp() {
    this.selectService();
    this.router.navigate([this.branchSlug, Path.EMPLOYEE], {
      queryParamsHandling: 'merge',
    });
  }

  goToTimeSetUp() {
    this.selectService();
    this.router.navigate([this.branchSlug, Path.DATE], {
      queryParamsHandling: 'merge',
    });
  }

  goToSummary() {
    this.selectService();
    this.goToHomePage()
  }

}
